import { Tv2NoVideo, Tv2NoVideoPlayer } from "../../snowtype/snowplow";
import { Asset } from "../types";

export type VideoInfoContext = {
  asset: Asset;
  category: string;
  videoSessionId: string;
  numberOfVideosWatched: number;
};

export const buildVideoInfoContext = ({
  asset,
  category,
  videoSessionId,
  numberOfVideosWatched,
}: VideoInfoContext): Tv2NoVideo => ({
  assetId: asset.id,
  assetName: asset.title,
  videoCategory: category,
  categoryTypeOfAction: asset.categoryTypeOfAction,
  videoLive: asset.isLive,
  videoName: asset.title,
  videoTags: asset.tags,
  publishedDate: asset.publishedDate,
  numberOfVideosWatched,
  videoSessionId,
});

export const buildVideoPlayerInfoContext = (): Tv2NoVideoPlayer & Record<string, unknown> => ({
  videoPlayerName: "TV 2 Klipp",
  videoPlayerVersion: "latest",
});
